import { FeatureFlagsState } from '@/store/modules/feature-flags/FeatureFlagsState';

export default {
  isEnabledFeatureFlag:
    (state: FeatureFlagsState) =>
    (name: string): boolean => {
      return !!state.features.find((feature) => feature.name === name)?.enabled;
    },

  featuresLoading(state: FeatureFlagsState): boolean {
    return state.loading;
  }
};
